export type CSSBaseKeywords = 'initial' | 'inherit' | 'unset'

export type CSSTextTransform =
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | CSSBaseKeywords

export type CSSTextAlign =
  | 'left'
  | 'center'
  | 'right'
  | 'auto'
  | CSSBaseKeywords

export type CSSFontWeight =
  | 'normal'
  | 'lighter'
  | 'bold'
  | 'bolder'
  | number
  | CSSBaseKeywords

export type CSSFontStyle = 'normal' | 'italic' | 'oblique' | CSSBaseKeywords

export type CSSWhiteSpace =
  | 'normal'
  | 'nowrap'
  | 'pre'
  | 'pre-line'
  | 'pre-wrap'
  | CSSBaseKeywords

export type CSSJustifyContent =
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'left'
  | 'right'
  | 'normal'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'
  | 'space-between'
  | 'space-evenly'
  | 'stretch' // Not supported in flexboxes
  | 'safe'
  | 'unsafe'

export type CSSButtonType = 'button' | 'submit' | 'reset'

export type CSSOverflow =
  | 'visible'
  | 'hidden'
  | 'scroll'
  | 'auto'
  | CSSBaseKeywords

export type CSSCursor =
  | 'alias'
  | 'all-scroll'
  | 'auto'
  | 'cell'
  | 'context-menu'
  | 'col-resize'
  | 'copy'
  | 'crosshair'
  | 'default'
  | 'e-resize'
  | 'ew-resize'
  | 'grab'
  | 'grabbing'
  | 'help'
  | 'move'
  | 'n-resize'
  | 'ne-resize'
  | 'nesw-resize'
  | 'ns-resize'
  | 'nw-resize'
  | 'nwse-resize'
  | 'no-drop'
  | 'none'
  | 'not-allowed'
  | 'pointer'
  | 'progress'
  | 'row-resize'
  | 's-resize'
  | 'se-resize'
  | 'sw-resize'
  | 'text'
  | 'vertical-text'
  | 'w-resize'
  | 'wait'
  | 'zoom-in'
  | 'zoom-out'
  | string // url(path/to/custom/pointer), default-value
  | CSSBaseKeywords

export type CSSResize =
  | 'none'
  | 'both'
  | 'horizontal'
  | 'vertical'
  | 'block'
  | 'inline'
  | CSSBaseKeywords

export enum ZIndexRange {
  Loaders = 3000,
  Globals = 4000,
  Sidebar = 4100,
  Header = 4200,
  Dropdowns = 5000,
  FormFields = 6000,
  Overlays = 6500,
  Modals = 7000,
  Toasts = 8000,
  Extra = 9000,
}
